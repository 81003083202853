<template>
  <van-cell :title="title">
    <template v-if="fileList.length > 0" v-slot:default>
      <van-uploader v-model="fileList" :deletable="false" :show-upload="false">
        <template v-slot:preview-cover="slotProps">
          <div class="preview-cover van-ellipsis">
            {{ slotProps.fileName }}
          </div>
          <a v-if="!utils.isImg(slotProps.fileUrl)" href="javascript:;" class="van-ellipsis down" @click="download(slotProps)">下载</a>
          <a v-else class="van-ellipsis down" @click="imagePreview(slotProps.fileUrl)">预览</a>
          <a v-if="slotProps.type==='pdf'" class="upload-pdf" @click="utils.uploadPdf(slotProps.path)">预览pdf</a>
        </template>
      </van-uploader>
    </template>
    <template v-else>
      <span> - </span>
    </template>
  </van-cell>
</template>

<script>
import { Col, Image, Row, CheckboxGroup, Icon, Cell, Uploader, ImagePreview } from 'vant'

export default {
  components: {
    [Image.name]: Image,
    [Col.name]: Col,
    [Row.name]: Row,
    [Icon.name]: Icon,
    [CheckboxGroup.name]: CheckboxGroup,
    [Cell.name]: Cell,
    [Uploader.name]: Uploader,
    [ImagePreview.name]: ImagePreview
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    fileList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  created () {
    if (this.fileList) {
      this.fileList.forEach((item, index) => {
        this.fileList[index].url = item.fileUrl
      })
    }
  },
  methods: {
    getFileType (name, file) {
      let type = ''
      if (file && file.type) {
        type = file.type.toLowerCase()
        return type
      }
      if (name) {
        const url = new URL(name)
        const index1 = url.pathname.lastIndexOf('.')
        const index2 = url.pathname.length
        type = url.pathname.substring(index1 + 1, index2).toLowerCase()
      }
      return type
    },
    isImg (name, file) {
      return (this.getFileType(name, file) === 'jpg' || this.getFileType(name, file) === 'jpeg' || this.getFileType(name, file) === 'png' || this.getFileType(name, file) === 'bmp' || this.getFileType(name, file) === 'gif')
    },
    handleDownload (item) {
      window.open(item.path)
    },
    imagePreview (url) {
      ImagePreview([url])
    },
    download (item) {
      const link = document.createElement('a')
      link.href = item.fileUrl
      link.target = '_blank'
      link.download = item.fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      // this.api.base.file.download({ fileId: item.fileId }).then(res => {
      //   const link = document.createElement('a')
      //   link.href = window.URL.createObjectURL(new Blob([res.data]))
      //   link.target = '_blank'
      //   link.download = item.fileName
      //   document.body.appendChild(link)
      //   link.click()
      //   document.body.removeChild(link)
      // })
    }
  }
}
</script>

<style lang="less" scoped>
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }
  .down {
    position: absolute;
    top: 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 4px;
    font-size: 12px;
    text-align: center;
  }
.van-cell__value {
      span {
        display: inline-block;
        text-align: left;
        word-break: break-all;
      }
    }
</style>
