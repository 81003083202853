var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("van-cell", {
    attrs: { title: _vm.title },
    scopedSlots: _vm._u(
      [
        _vm.fileList.length > 0
          ? {
              key: "default",
              fn: function() {
                return [
                  _c("van-uploader", {
                    attrs: { deletable: false, "show-upload": false },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "preview-cover",
                          fn: function(slotProps) {
                            return [
                              _c(
                                "div",
                                { staticClass: "preview-cover van-ellipsis" },
                                [_vm._v(" " + _vm._s(slotProps.fileName) + " ")]
                              ),
                              !_vm.utils.isImg(slotProps.fileUrl)
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "van-ellipsis down",
                                      attrs: { href: "javascript:;" },
                                      on: {
                                        click: function($event) {
                                          return _vm.download(slotProps)
                                        }
                                      }
                                    },
                                    [_vm._v("下载")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticClass: "van-ellipsis down",
                                      on: {
                                        click: function($event) {
                                          return _vm.imagePreview(
                                            slotProps.fileUrl
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("预览")]
                                  ),
                              slotProps.type === "pdf"
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "upload-pdf",
                                      on: {
                                        click: function($event) {
                                          return _vm.utils.uploadPdf(
                                            slotProps.path
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("预览pdf")]
                                  )
                                : _vm._e()
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1955092160
                    ),
                    model: {
                      value: _vm.fileList,
                      callback: function($$v) {
                        _vm.fileList = $$v
                      },
                      expression: "fileList"
                    }
                  })
                ]
              },
              proxy: true
            }
          : {
              key: "default",
              fn: function(undefined) {
                return [_c("span", [_vm._v(" - ")])]
              }
            }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }